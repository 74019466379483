import { Titles } from "@/constants";
import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalized(text: string) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function removeSpecialCharacters(str: string): string {
  if (!str || typeof str !== "string") return str;
  return str.replace(/[^a-zA-Z0-9\s]/g, "");
}

export function camelToCapitalized(str: string): string {
  // Split the string into an array of words
  const words = str.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  // Join the formatted words with a space
  let formattedStr = formattedWords.join(" ");

  // Capitalize the first letter of the resulting string
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

  return formattedStr;
}

export interface NameParts {
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
}
export function nameObjFromText(str: string | null): NameParts {
  // Initialize default return object
  const result: NameParts = {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
  };
  if (!str) return result;

  const cleanStr = str
    .replace(/[^\w\s-_]/g, "")
    .replace(/[-_]/g, " ")
    .trim();

  // Split into words and filter out empty strings
  const words = cleanStr.split(/\s+/).filter((word) => word.length > 0);

  // Handle different cases based on number of words
  switch (words.length) {
    case 0:
      return result;

    case 1:
      result.firstName = words[0];
      return result;

    case 2:
      result.firstName = words[0];
      result.lastName = words[1];
      return result;

    case 3:
      // Check if first word is a title
      const isTitle = Titles.some(
        (t) => t.value.toLowerCase() === words[0].toLowerCase()
      );

      if (isTitle) {
        result.title = words[0];
        result.firstName = words[1];
        result.lastName = words[2];
      } else {
        result.firstName = words[0];
        result.middleName = words[1];
        result.lastName = words[2];
      }
      return result;

    case 4:
      // Check if first word is a title
      const hasTitle = Titles.some(
        (t) => t.value.toLowerCase() === words[0].toLowerCase()
      );

      if (hasTitle) {
        result.title = words[0];
        result.firstName = words[1];
        result.middleName = words[2];
        result.lastName = words[3];
      } else {
        result.firstName = words[0];
        result.middleName = words[1] + " " + words[2];
        result.lastName = words[3];
      }
      return result;

    default: // More than 4 words
      const firstIsTitle = Titles.some(
        (t) => t.value.toLowerCase() === words[0].toLowerCase()
      );

      if (firstIsTitle) {
        result.title = words[0];
        result.firstName = words[1];
        result.middleName = words.slice(2, -1).join(" ");
        result.lastName = words[words.length - 1];
      } else {
        result.firstName = words[0];
        result.middleName = words.slice(1, -1).join(" ");
        result.lastName = words[words.length - 1];
      }
      return result;
  }
}

export function validDate(date: any): Date | null {
  if (!date || typeof date === "boolean") return null;

  const validDate = dayjs(date).isValid() ? new Date(date) : null;

  return validDate;
}
