export { default as pageLinks } from "./pagelinks";
export { default as endpoints } from "./endpoints";
export { default as queryKeys } from "./querykeys";
export { default as mantineTheme } from "./mantineTheme";

export const orderStatus = {
  orderCreated: "order-created",
  receivedOrder: "received-order",
  pendingOrder: "pending-order",
  prelimResult: "prelim-result",
  finalizedResult: "finalized-result",
  released: "released",
};
export const USAStates = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
  { value: "District of Columbia", label: "District of Columbia" },
];

export const Countries = [
  { value: "United States", label: "United States" },
  { value: "Canada", label: "Canada" },
  { value: "Mexico", label: "Mexico" },
  { value: "Brazil", label: "Brazil" },
  { value: "Argentina", label: "Argentina" },
  { value: "Colombia", label: "Colombia" },
  { value: "Chile", label: "Chile" },
  { value: "Peru", label: "Peru" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Cuba", label: "Cuba" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Panama", label: "Panama" },
  { value: "Honduras", label: "Honduras" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Paraguay", label: "Paraguay" },
];

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  USER: "USER",
  PROVIDER: "PROVIDER",
  PATIENT: "PATIENT",
  CLINICIAN: "CLINICIAN",
} as const;

export const customerType = {
  CLINICAL: "clinical",
  ANATOMICAL: "anatomical",
};

const roleSet = new Set(Object.values(ROLES));

const showRoles = {
  SUPER_ADMIN: "Admin",
  ADMIN: "Master User",
  USER: "Accessioner",
  PROVIDER: "Provider",
  PATIENT: "Patient",
  CLINICIAN: "Clinician",
  // ACCESSIONER: "Accessioner",
  // MASTER_USER: "Master User",
};

export const resultState = {
  positive: "positive",
  negative: "negative",
  none: "none",
};

export const OrgTypes = [
  {
    value: "hospital",
    label: "Hospital",
  },
  {
    value: "laboratory",
    label: "Laboratory",
  },
  {
    value: "medical-center",
    label: "Medical Center",
  },
  {
    value: "multi-clinic",
    label: "Multi Clinic",
  },
  {
    value: "clinic",
    label: "Clinic",
  },
];

export const Titles = [
  { value: "Mrs", label: "Mrs" },
  { value: "Miss", label: "Miss" },
  { value: "Mr", label: "Mr" },
  { value: "Dr", label: "Dr" },
];

export const role = (role: keyof typeof showRoles) => {
  if (roleSet.has(role)) {
    return showRoles[role as keyof typeof showRoles];
  }
  return "";
};

export const genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "unknown", label: "Unknown" },
];

export const maritalStatus = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "divorced", label: "Divorced" },
  { value: "widow", label: "Widow" },
];

export const ethnicity = [
  { value: "hispanic", label: "Hispanic" },
  { value: "not-hispanic", label: "Not-Hispanic" },
  { value: "unknown", label: "Unknown" },
];

export const race = [
  { value: "white", label: "White" },
  {
    value: "black-or-african-american",
    label: "Black or African American",
  },
  {
    value: "american-indian-and-alaska-native",
    label: "American Indian and Alaska Native",
  },
  { value: "asian", label: "Asian" },
  {
    value: "native-hawaiian-and-other-pacific-islander",
    label: "Native Hawaiian and Other Pacific Islander",
  },
  {
    value: "hispanic-or-latino",
    label: "Hispanic or Latino",
  },
  { value: "other", label: "Other" },
];

export const VENDOR_CONNECTION_INTEGRATION_METHODS = [
  { value: "FTP", label: "FTP" },
  { value: "FHIR", label: "FHIR" },
  { value: "HL7", label: "HL7" },
  { value: "CUSTOM_API", label: "Custom Api" },
];

export const BULK_REPORT_TYPES = [
  {
    label: "Accounting Report",
    value: "accounting-report",
  },
  // {
  //   label: "Amended Result Report",
  //   value: "amended-result-report",
  // },
  // {
  //   label: "Batch Label",
  //   value: "batch-label",
  // },
  // {
  //   label: "Batch Label by Panels",
  //   value: "batch-label-panels",
  // },
  {
    label: "Batch Order Audit",
    value: "batch-order-audit",
  },
  {
    label: "Batch Requisition",
    value: "batch-requisition",
  },
  {
    label: "Batch Result",
    value: "batch-result",
  },
  // {
  //   label: "Batch Result Panel Filter",
  //   value: "batch-result-panel",
  // },
  {
    label: "Billing",
    value: "billing",
  },
  {
    label: "Detected Summary Report",
    value: "detected-summary-report",
  },
  {
    label: "Flag Result Report",
    value: "flag-result-report",
  },
  {
    label: "Inconsistent Summary",
    value: "inconsistent-summary",
  },
  {
    label: "Instrument Report",
    value: "instument-report",
  },
  {
    label: "Method Result",
    value: "method-result",
  },
  {
    label: "Patient Listing Report",
    value: "patient-listing-report",
  },
  {
    label: "Received Samples Report",
    value: "received-samples-report",
  },
  {
    label: "Requisition Listing",
    value: "requisition-listing",
  },
  {
    label: "Test Cancellation Report",
    value: "test-cancellation-report",
  },
];

export const resultReport = [
  {
    value: "9001",
    label: "Method Result Report",
  },
  {
    value: "101",
    label: "Result Report 101 (TOX)",
  },
  {
    value: "102",
    label: "Result Report 102",
  },
  {
    value: "103",
    label: "Result Report 103 (TOX)",
  },
  {
    value: "104",
    label: "Result Report 104",
  },
  {
    value: "105",
    label: "Result Report 105 (TOX)",
  },
  {
    value: "1052",
    label: "Result Report 105-Signature (TOX)",
  },
  {
    value: "1053",
    label: "Result Report 105ESA (TOX)",
  },
  {
    value: "1051",
    label: "Result Report 105V (TOX)",
  },
  {
    value: "106",
    label: "Result Report 106 (TOX)",
  },
  {
    value: "107",
    label: "Result Report 107",
  },
  {
    value: "1071",
    label: "Result Report 107a (TOX)",
  },
  {
    value: "108",
    label: "Result Report 108",
  },
  {
    value: "109",
    label: "Result Report 109",
  },
  {
    value: "110",
    label: "Result Report 110 (TOX)",
  },
  {
    value: "1101",
    label: "Result Report 110APS (MOLECULAR)",
  },
  {
    value: "1104",
    label: "Result Report 110APS-QR",
  },
  {
    value: "1105",
    label: "Result Report 110APU",
  },
  {
    value: "1102",
    label: "Result Report 110L (TOX)",
  },
  {
    value: "1103",
    label: "Result Report 110NONA (CORE LAB)",
  },
  {
    value: "111",
    label: "Result Report 111 (TOX)",
  },
  {
    value: "1112",
    label: "Result Report 111NONA (TOX)",
  },
  {
    value: "112",
    label: "Result Report 112 (CORE LAB)",
  },
  {
    value: "113",
    label: "Result Report 113 (TOX)",
  },
  {
    value: "114",
    label: "Result Report 114 (TOX)",
  },
  {
    value: "1142",
    label: "Result Report 114-2 (TOX)",
  },
  {
    value: "1143",
    label: "Result Report 114-3 (TOX)",
  },
  {
    value: "1144",
    label: "Result Report 114-4 (AAD)",
  },
  {
    value: "115",
    label: "Result Report 115 (NBTS) (TOX)",
  },
  {
    value: "116",
    label: "Result Report 116 (TOX)",
  },
  {
    value: "1164",
    label: "Result Report 116A (TOX)",
  },
  {
    value: "1163",
    label: "Result Report 116CLAR (TOX)",
  },
  {
    value: "1165",
    label: "Result Report 116DTR (TOX)",
  },
  {
    value: "1162",
    label: "Result Report 116h (TOX)",
  },
  {
    value: "1161",
    label: "Result Report 116V (TOX)",
  },
  {
    value: "117",
    label: "Result Report 117 (TOX)",
  },
  {
    value: "1171",
    label: "Result Report 117-1 (TOX)",
  },
  {
    value: "118",
    label: "Result Report 118 (TOX)",
  },
  {
    value: "1181",
    label: "Result Report 118-B (TOX)",
  },
  {
    value: "119",
    label: "Result Report 119 (TOX)",
  },
  {
    value: "1191",
    label: "Result Report 119-1 (TOX)",
  },
  {
    value: "1193",
    label: "Result Report 119-1TG (TOX)",
  },
  {
    value: "1194",
    label: "Result Report 119MED (TOX)",
  },
  {
    value: "1195",
    label: "Result Report 119PLC (TOX)",
  },
  {
    value: "1192",
    label: "Result Report 119TG (TOX)",
  },
  {
    value: "120",
    label: "Result Report 120 (TOX)",
  },
  {
    value: "1202",
    label: "Result Report 120DY (TOX)",
  },
  {
    value: "1201",
    label: "Result Report 120TG (TOX)",
  },
  {
    value: "201",
    label: "Result Report 201 (TOX)",
  },
  {
    value: "202",
    label: "Result Report 202 (TOX)",
  },
  {
    value: "2021",
    label: "Result Report 202D (TOX)",
  },
  {
    value: "203",
    label: "Result Report 203 (TOX)",
  },
  {
    value: "204",
    label: "Result Report 204 (TOX)",
  },
  {
    value: "2041",
    label: "Result Report 204B (TOX)",
  },
  {
    value: "205",
    label: "Result Report 205 (CORE LAB)",
  },
  {
    value: "206",
    label: "Result Report 206 (TOX)",
  },
  {
    value: "2063",
    label: "Result Report 206.1_SVL",
  },
  {
    value: "2062",
    label: "Result Report 206_SVL",
  },
  {
    value: "2061",
    label: "Result Report 206_V2 (TOX)",
  },
  {
    value: "207",
    label: "Result Report 207 (CORE LAB)",
  },
  {
    value: "2072",
    label: "Result Report 207A (CORE LAB)",
  },
  {
    value: "2074",
    label: "Result Report 207ARP (CORE LAB)",
  },
  {
    value: "2071",
    label: "Result Report 207G (CORE LAB)",
  },
  {
    value: "2073",
    label: "Result Report 207V (CORE LAB)",
  },
  {
    value: "208",
    label: "Result Report 208 (TOX)",
  },
  {
    value: "209",
    label: "Result Report 209RD",
  },
  {
    value: "2091",
    label: "Result Report 209RD.1",
  },
  {
    value: "2092",
    label: "Result Report 209RD.2",
  },
  {
    value: "2101",
    label: "Result Report 210.1CVD",
  },
  {
    value: "210",
    label: "Result Report 210CVD (MOLECULAR)",
  },
  {
    value: "211",
    label: "Result Report 211",
  },
  {
    value: "5011",
    label: "Result Report 501.1M (MOLECULAR)",
  },
  {
    value: "501",
    label: "Result Report 501M (MOLECULAR)",
  },
  {
    value: "5021",
    label: "Result Report 502.1M (MOLECULAR)",
  },
  {
    value: "5022",
    label: "Result Report 502.2M (MOLECULAR)",
  },
  {
    value: "502",
    label: "Result Report 502M (MOLECULAR)",
  },
  {
    value: "5031",
    label: "Result Report 503.1M (MOLECULAR)",
  },
  {
    value: "503",
    label: "Result Report 503M (MOLECULAR)",
  },
  {
    value: "5044",
    label: "Result Report 504.1MRLT",
  },
  {
    value: "504",
    label: "Result Report 504M (MOLECULAR)",
  },
  {
    value: "5045",
    label: "Result Report 504M_PLC",
  },
  {
    value: "5043",
    label: "Result Report 504MNIU",
  },
  {
    value: "5041",
    label: "Result Report 504MRLT",
  },
  {
    value: "5042",
    label: "Result Report 504MRND",
  },
  {
    value: "506",
    label: "Result Report 506 UTI (Molecular)",
  },
  {
    value: "5061",
    label: "Result Report 506V (MOLECULAR)",
  },
  {
    value: "507",
    label: "Result Report 507M (Microbiology)",
  },
  {
    value: "601",
    label: "Result Report 601P",
  },
];

export const requisitionReport = [
  {
    value: "200",
    label: "Requisition Report - LabCorp",
  },
  {
    value: "105",
    label: "Requisition Report - Signature",
  },
  {
    value: "101",
    label: "Requisition Report 101",
  },
  {
    value: "1012",
    label: "Requisition Report 101B",
  },
  {
    value: "1011",
    label: "Requisition Report 101V",
  },
  {
    value: "102",
    label: "Requisition Report 102",
  },
  {
    value: "103",
    label: "Requisition Report 103",
  },
  {
    value: "104",
    label: "Requisition Report 104",
  },
  {
    value: "106",
    label: "Requisition Report 106",
  },
  {
    value: "1071",
    label: "Requisition Report 107",
  },
  {
    value: "107",
    label: "Requisition Report V",
  },
];