import { createTheme } from "@mantine/core";

const theme = createTheme({
  colors: {
    main: [
      "#ffecf5",
      "#f7d8e6",
      "#ecaeca",
      "#e281ae",
      "#d85c95",
      "#d34586",
      "#d2377f",
      // "#ba2a6d",
      "#AE2465",
      "#a72361",
      "#931753",
    ],
  },
  components: {
    Button: {
      defaultProps: {
        color: "main",
      },
    },
    Checkbox: {
      defaultProps: {
        color: "main",
      },
      classNames: {
        label: "!text-primary",
      },
    },
    Select: {
      classNames: {
        input: "!bg-background",
        label: "mb-1",
        // dropdown: "!bg-background"
      },
    },
    MultiSelect: {
      classNames: {
        input: "!bg-background",
        label: "mb-1",
        // dropdown: "!bg-background"
      },
    },
    TextInput: {
      classNames: {
        input: "!bg-background",
        label: "mb-1",
      },
    },
    Textarea: {
      classNames: {
        input: "!bg-background",
        label: "mb-1",
      },
    },
    TimeInput: {
      classNames: {
        input: "!bg-background",
        label: "mb-1",
      },
    },
    DatePickerInput: {
      classNames: {
        input: "!bg-background",
      },
    },
    DateInput: {
      classNames: {
        input: "!bg-background",
      },
    },
    Switch: {
      defaultProps: {
        color: "var(--main-color-hex)",
      },
    },
  },
});

export default theme;
