type env = "production" | "staging" | "local";

const APP_ENV: env = (process.env.APP_ENV as env) ?? "staging";
const BASE_URL = process.env.BASE_URL;

const DOCUAI_BASE_URL = process.env.NEXT_PUBLIC_DOCUAI_BASE_URL;
const DOCUAI_SECRET = process.env.NEXT_PUBLIC_DOCUAI_SECRET;

const SENTRY_DSN = process.env.SENTRY_DSN;
const IS_SENTRY_ENABLED =
  APP_ENV === "staging" ?? APP_ENV === "production" ?? false;

export {
  APP_ENV,
  BASE_URL,
  SENTRY_DSN,
  IS_SENTRY_ENABLED,
  DOCUAI_BASE_URL,
  DOCUAI_SECRET,
};
